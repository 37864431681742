import './import/modules.js';
// import "./import/components.js";

// import $ from "jquery";

// $(document).ready(() => {
//   alert("JS is work");
//   console.log("JS is work");
// });

console.log('JS is Work');
